.idleModalButtons {
  display: flex;
  justify-content: space-between;
}

.modalTime {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
